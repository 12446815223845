import { FOCUSABLE_ELEMENTS, handleFocus } from "./lib/handlefocus.js"

$(document).ready(function () {
    // ===================
    // 要素等
    // ===================
    const ariaExpanded = 'aria-expanded';
    const ariaHidden = 'aria-hidden';
    const body = $('body');
    // bodyに付与するclass
    const classBodyGnavOpen = 'gnav-open';
    const classBodySubNavOpen = 'child-menu-open';
    // 要素
    const header_class = '.f__header';
    const header = $(header_class);
    const gnav = $(header_class + ' #gnav');
    const btnGnav = $(header_class + ' #menu-btn > button');
    const btnSubNav = $(header_class + ' #gnav .menu li > button');
    // media query
    const mediaQueryList = window.matchMedia('(min-width: 768px)');

    // ===================
    // gnav
    // ===================
    // gnav開閉
    function toggleGnav(isOpen) {
        const state = isOpen ? 'true' : 'false';
        btnGnav.attr(ariaExpanded, state);
        gnav.attr(ariaHidden, state === 'true' ? 'false' : 'true');
        body.toggleClass(classBodyGnavOpen, isOpen);
    }
    // menu btn押下時にgnav開閉
    btnGnav.click(function () {
        const isOpen = btnGnav.attr(ariaExpanded) === 'true';
        toggleGnav(!isOpen);
    });

    // ===================
    // sub nav
    // ===================
    // sub nav閉じる
    const closeSubNav = (button, subNavElement) => {
        $(button).attr(ariaExpanded, 'false');
        $(subNavElement).attr(ariaHidden, 'true');
        $(subNavElement).first().slideUp(300);
    };
    // sub nav開く
    const openSubNav = (button, subNavElement) => {
        $(button).attr(ariaExpanded, 'true');
        $(subNavElement).attr(ariaHidden, 'false');
        $(subNavElement).first().slideDown(300);
    };
    // sub navのボタン押下時に開閉
    btnSubNav.each(function () {
        $(this).click(function (e) {
            const button = e.currentTarget;
            const isOpen = $(button).attr(ariaExpanded) == 'true';
            if (isOpen) {
                closeSubNav(button, button.nextElementSibling);
                body.removeClass(classBodySubNavOpen);
            } else {
                btnSubNav.each(function () {
                    if (this !== button) { // Check if it's not the clicked element
                        closeSubNav(this, this.nextElementSibling);
                    }
                });
                openSubNav(button, button.nextElementSibling);
                body.addClass(classBodySubNavOpen);
            }
        });
    });

    // gnav または sub navが開いている場合はheader内でフォーカスを回す
    const focusableHeaderElements = header.find(FOCUSABLE_ELEMENTS.join(","));
    const checkHeaderCondition = () => body.hasClass(classBodyGnavOpen) || body.hasClass(classBodySubNavOpen);
    header.keydown(function (e) {
        handleFocus(focusableHeaderElements.toArray(), checkHeaderCondition, e); // Adjust for jQuery to array conversion
    });

    // ===================
    // PC / SP切り分け
    // ===================
    const listener = (event) => {
        $(document).keydown(function (e) {
            // Escapeキー押下
            if (e.code === 'Escape') {
                if (!mediaQueryList.matches && body.hasClass(classBodyGnavOpen)) {
                    // SP : gnavが開いているとき
                    toggleGnav(false);
                    btnSubNav.each(function () {
                        closeSubNav(this, this.nextElementSibling);
                    });
                    body.removeClass(classBodySubNavOpen);
                } else if (mediaQueryList.matches && body.hasClass(classBodySubNavOpen)) {
                    // PC : sub navが開いているとき
                    btnSubNav.each(function () {
                        closeSubNav(this, this.nextElementSibling);
                    });
                    body.removeClass(classBodySubNavOpen);
                }
            }
        });
        if (event.matches) {
            // PC
        } else {
            // SP
            toggleGnav(false);
        }
    };

    mediaQueryList.addListener(listener);
    listener(mediaQueryList);
});

// ===================
// 画面に入ったらふわっと表示
// ===================
$(window).scroll(function () {
    $('[data-fadein]').each(function () {
        var elemPos = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > elemPos - windowHeight + 100) {
            $(this).addClass('scrollin');
        }
    });
});
